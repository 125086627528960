<template>
  <div class="followUpPage">
    <v-app id="vApp">
    <!-- Evolve Overlay -->
      <FollowUpOverlay
        :overlay-type="overlayType"
        :parent-view="'Followup'"
        id="followUpOverlay"
        v-on:overlay-off="overlayOff"
        />





      <VueFooter />
    </v-app>
  </div>
</template>

<script>
  //import somethingModule from '@/store/something'
  import feedbackModule from '@/store/feedback'
  import experienceModule from '@/store/experience'
  import { mapGetters } from 'vuex'
  import FollowUpOverlay from '@/components/FollowUpOverlay'
  import VueFooter from '@/components/VueFooter'

  export default {
    name: "follow-up",
    components: {
      FollowUpOverlay,
      VueFooter
    },
    data () {
      return {
        overlayType: null,

        
      }
    },
    computed: {
      ...mapGetters('auth', [
        'profile',
        'loggedIn',
        'role'
      ])
    },
    methods: {
      showOverlay (payload) {
        this.overlayType = payload.overlayType

        window.scrollTo(0,0)
        document.getElementById("followUpOverlay").style.display = "block";
      },
      overlayOff () {
        
        this.overlayType = null
        window.scrollTo(0,0)
        document.getElementById("followUpOverlay").style.display = "none";
      }
    },
    beforeCreate () {
      //if(!this.$store.state.something) this.$store.registerModule('something', somethingModule)
      if(!this.$store.state.feedback) this.$store.registerModule('feedback', feedbackModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
    },
    created () {
      var nextStepId  = this.$route.params.nextStepId
      if(nextStepId) {
        if(nextStepId === 'onInitiative') {
          this.showOverlay({
            'overlayType': 'onInitiative',
          })
        }
      }
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/category'});
    },
    mounted () {

    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

</style>