<template>
  <div class="followUpOverlay" :id="'followUpOverlay'">
    <div class="wrapper">
      <div id="logo">
        <img :src="require('@/assets/heedi_homepage_logo_red_solo.png')" alt="heedi">
      </div>
      <div class="titleHolder">
        
        <div  class="titleIcon"><i class="fas fa-compress-arrows-alt"></i></div>

      </div>
      


      
    </div>
    <!-- Parent Category Image -->
      <div class="imageHolder">
        <img class="category-img" id="category-img" v-bind:src="this.categoryImageSrc" />
        <div class="imageCaption" >
          <a :href="this.categoryImageLink"><div v-bind:key="'yo'+categoryCreditText">{{ this.categoryCreditText }}</div></a>
        </div>
      </div>
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    //import firebase from '@/firebase'
    //import authModule from '@/store/auth'
    //import tallyModule from '@/store/tally'
    //import signinModule from '@/store/signin'
    //import experienceModule from '@/store/experience'
    //import minderModule from '@/store/minder'
    //import nextStepModule from '@/store/nextstep'
    //import settingsModule from '@/store/settings'
    //import { mapGetters, mapActions } from 'vuex'
    //import imageModule from '@/store/image'
    //import momentModule from '@/store/moment'
    //import routineModule from '@/store/routine'
    //import statementModule from '@/store/statement'
    //import notificationModule from '@/store/notification'
    //import moment from 'moment-timezone'


    //import '@/plugins/vuetify'
    //import ThoughtIntake from '@/components/ThoughtIntake'

  export default {
    name: "FollowUpOverlay",
    components: {
      //ThoughtIntake
    },
    props: {
      
      overlayType: {
        type: String
      },
      
      parentView: {
        type: String
      },
    },
    data () {
      return {


        currentView: 'INSERT_VIEW_HERE',
        imageComplete: false,
        categoryImageSrc: '',
        categoryCreditText: '',
        categoryImageLink: '',




        
      }
    },
    computed: {
      //...mapGetters('cms', [
      //  'searchStimulusText',
      //])
      
    },
    watch: {
      parentCategory: function(newValue) {
        if(newValue && this.category && !this.imageComplete) {  //I have incoming parameters that arrive at different times.
          
          //var newCat = this.category.replace(/-/g, ' ');
          var newCatSlug = this.category.replace(/\s+/g, '-').toLowerCase();
          var newParentCatSlug = this.parentCategory.replace(/\s+/g, '-').toLowerCase();
          var obj = {
            'category': this.category,
            'parentCategory': newValue,
            'categorySlug': newCatSlug,
            'parentCategorySlug': newParentCatSlug,
            'categoryType': "intentMean"
          }
        
          this.getCategoryImage(obj).then((image) => {   //If its a bad category, there will be no hero image
            
            this.categoryImageSrc = image.src
            this.categoryCreditText = image.credit
            this.categoryImageLink = image.link
            this.imageComplete = true
            //show the image
            document.getElementById('category-img').style.display = "block";
            this.getUnitOfMeasures(newValue)
            //console.log("got image")

            
          })
        } 
      },
      overlayType: function(newValue) {
        if(newValue) {
          this.initializeFlow()
          this.currentStep = 1
          this.goToStep(this.currentStep)
        }
        
      },
    },
    methods: {
      //...mapActions('image', [
      //  'getCategoryImage'
      //]),
      
      overlayOff () {
        
        this.$emit('overlay-off')
        
      },
      
      


    },
    beforeCreate () {
      //if(!this.$store.state.auth) this.$store.registerModule('auth', authModule)
      
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();
      //this.randomNumber = this.generateRandomNumber()
      //this.flowInitialized = false;
      
      
    
    },
    mounted () {
      //if(this.parentCategory && !this.flowInitialized) {
      //  this.initializeFlow()
      //  this.flowInitialized = true
      //}

      
    },
    updated () {
      this.setShowSearch(false)
      //this.randomNumber = this.generateRandomNumber()
    }
  }
</script>

<style scoped>

.wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0;
}

a {
  color: #777;
}


.followUpOverlay {
  position: absolute; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.97); /* Black background with opacity */
  z-index: 1100; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  vertical-align: top;
  font-size: 1.5em;
  text-align: left;
  
}
.overlayTitle {
  display: inline-block;
  color: white;
  font-weight: 300;
  font-size: .8em;

}
#logo {
  position: absolute;
  z-index: 1001;
  left: 8px;
  bottom: 28px;
}
#logo > img {
    height: 64px;
  }

.category-img {
  display: none;
  background: rgba(34, 34, 34, 1);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 30px left;
  object-position: 30px left;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  
}

.imageHolder {
  position: relative;
  background-color: transparent;
  margin: 0 auto;
}
.imageCaption {
  position: absolute;
  bottom: -40px;
  left: 50%;
  font-size: .6em;
  transform: translateX(-50%);
  
  
}



.titleHolder {
  position: relative;
  color: rgb(230, 230, 230);
  font-size: .85em;
  font-weight: bold;
  background-color: #222;
  padding: 15px;
  padding-right: 50px;
  padding-left: 50px;
  text-align: center;
  overflow: hidden;
  max-width: 900;
}
.titleIcon {
  position: absolute;
  color: rgb(90, 90, 90);
  font-size: 1.4em;
  top: 7px;
  left: 15px;
}



/*Styles for Step Cards and elements*/
.stepCard {
  background-color: white;
  padding: 15px;
  min-height: 295px;
}
.intro {
  background-color: transparent;
  color: lightgray;
  font-size: 1.4em;
  padding-top: 5px;
}

.stimulusQuestion {
  min-height: 80px;
}
.column {
  vertical-align: top;
  display: inline-block;
  width: 50%;
}
.switchColumn {
  width: 15%;
  min-width: 40px;
  background-color: transparent;
  display:inline-block;
  text-align: right;
}
.switchLabel {
  width: 80%;
  background-color: transparent;
  display: inline-block;
  font-size: 1.3em;
  padding-left: 16px;
}
.stepButtonHolder {
  text-align: right;
  padding: 4px;
}
.stepTextField {
  
  margin: 0 auto;
  margin-top: 30px;
}
.lessMarginTop {
  margin-top: 20px;
}
.lessMinHeight {
  min-height: 20px;
}


.redFont {
  color: red;
}

.v-input--selection-controls {
  margin-top: 0px;
}
.notificationSwitchHolder {
  margin: 0 auto;
  width: 90%;
  
  background-color: transparent;
}

@media screen and (min-width: 360px) {

  .stepCard {
    padding: 25px;
    min-height: 330px;
  }

  .dotsHolder {
    margin-bottom: 20px;
    margin-top: 15px;
  }


  .titleHolder {
    font-size: .90em;
  }

  .titleIcon {
    font-size: 1.5em;
  }

  .stepTextField {
    
    margin-top: 50px;
  }

  .intro {
    font-size: 1.6em
  }

}

@media screen and (min-width: 400px) {

  .titleHolder {
    font-size: .95em;
    padding-right: 50px;
    padding-left: 50px;
  }

  .titleIcon {
    font-size: 1.7em;
  }

  .notificationSwitchHolder {
    width: 70%;
  }

  .intro {
    font-size: 1.75em
  }

  .stepCard {
    padding: 25px;
    min-height: 350px;
  }

}
@media screen and (min-width: 500px) {

  .titleHolder {
    font-size: 1.2em;
    padding-right: 50px;
    padding-left: 50px;
  }

  .titleIcon {
    font-size: 1.5em;
  }

  .notificationSwitchHolder {
    width: 60%;
  }

  

}

@media screen and (min-width: 750px) {

  .titleHolder {
    font-size: 1.3em;
  }

  .titleIcon {
    font-size: 1.5em;
  }

 .intro {
    font-size: 1.85em
  }
  
  .stepHolder {
    max-width: 800px;
  }

}






</style>